"use strict";
export const utilFunctions = {
  onload: function onloadGeneric(scope, data) {
    var calculatedFields = {};
    var calculatedFromTableFields = {};
    var activeTab = scope.activeTab;
    var getFromTableData = scope.getFromTableData;
    var parseValue = scope.parseValue;

    for (var field in activeTab.fields) {
      if (
        activeTab.fields[field].calculated != null &&
        activeTab.fields[field].filter == null &&
        activeTab.fields[field].fromTable == null
      ) {
        calculatedFields[field] = activeTab.fields[field].calculated;
      }
      if (
        activeTab.fields[field].calculated != null &&
        activeTab.fields[field].filter == null &&
        activeTab.fields[field].fromTable != null
      ) {
        calculatedFromTableFields[field] = activeTab.fields[field].calculated;
      }
    }

    var previousFieldFromTable = null;
    var fromTableData;

    data.forEach(function (el) {
      for (var calcField in calculatedFields) {
        el[calcField] = eval(
          calculatedFields[calcField].replace(new RegExp("{}", "g"), "el").replace(new RegExp("><.", "g"), "")
        );
      }
      for (var calcFromField in calculatedFromTableFields) {
        if (el[calcFromField] == null || el[calcFromField] == undefined || activeTab.forceCalculate) {
          if (activeTab.fields[calcFromField].fromTable != previousFieldFromTable) {
            fromTableData = getFromTableData(activeTab.fields[calcFromField].fromTable);
          }
          previousFieldFromTable = activeTab.fields[calcFromField].fromTable;
          if (fromTableData != null) {
            el[calcFromField] = eval(
              calculatedFromTableFields[calcFromField]
                .replace(new RegExp("{}", "g"), "el")
                .replace(new RegExp("<>", "g"), "fromTableData")
                .replace(new RegExp("not like", "g"), "fromTableData")
                .replace(new RegExp("><", "g"), "scope")
            );
          }
        }
      }
    });
  },
  ptSplitAnnualActivityPlanRecordsBySchool: function ptSplitAFCRecords(scope, data, pdfMode) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      for (let j = 0; j < el.school.length; j++) {
        const activitySchool = el.school[j];
        let newElement = JSON.parse(JSON.stringify(el));
        newElement.school = activitySchool;
        newElement.envolvedSchools = scope.parseValue(el.school, "school", "Class_Plan_Schools");
        if (el.class != null && Array.isArray(el.class) && el.class.length > 0) {
          newElement.envolvedClasses = scope.parseValue(el.class, "class", "Class_Plan_Classes");
        }
        if (el.subject != null && Array.isArray(el.subject) && el.subject.length > 0) {
          let parsedActivitySubjects = scope.parseValue(el.subject, "subject", "Class_Plan_Subjects");
          if (parsedActivitySubjects.length > 0) {
            parsedActivitySubjects = [...new Set(parsedActivitySubjects.split(","))];
            newElement.parsedActivitySubjects = parsedActivitySubjects.sort();
          }
        }
        if (newElement.activityStartDateMs != null && newElement.activityStartDateMs != "") {
          newElement.activityStartDateMs = new Date(newElement.activityStartDateMs).toLocaleDateString();
        }
        if (newElement.activityEndDateMs != null && newElement.activityEndDateMs != "") {
          newElement.activityEndDateMs = new Date(newElement.activityEndDateMs).toLocaleDateString();
        }
        if (
          newElement.activityStartDateMs != null &&
          newElement.activityStartDateMs != "" &&
          newElement.activityEndDateMs != null &&
          newElement.activityEndDateMs != "" &&
          newElement.activityStartDateMs != newElement.activityEndDateMs
        ) {
          newElement.distinctActivityDates = true;
        }
        newElement.splitFlag = true;
        newElement.createdAtStart = newElement.createdAt;
        newElement.createdAtEnd = newElement.createdAt;
        cleanNewElement(newElement);
        data.push(newElement);
      }

      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  etiCAFSplitRecords: function etiCAFSplitRecords(scope, data) {
    let studentsData = scope.getFromTableData("KSTK_Students");
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentAttendanceRecords && el.studentAttendanceRecords.length > 0) {
        for (let k = 0; k < el.studentAttendanceRecords.length; k++) {
          let studAttendance = el.studentAttendanceRecords[k];
          if (studAttendance.monthlyTotal != null) {
            let studRecord = studentsData.filter((el) => el.id == studAttendance.studentID)[0];
            if (studRecord != null) {
              if (scope.activeTabName == "Mensalidades") {
                let newStudAttendance = JSON.parse(JSON.stringify(el));
                getMainStudAttendanceAttributes(studAttendance, newStudAttendance, studRecord);
                newStudAttendance.parsedSchedule = studAttendance.parsedSchedule;
                data.push(newStudAttendance);
              } else {
                studAttendance.schedule.forEach((schedule) => {
                  let newStudAttendance = JSON.parse(JSON.stringify(el));
                  getMainStudAttendanceAttributes(studAttendance, newStudAttendance, studRecord);
                  newStudAttendance.schedule = schedule;
                  data.push(newStudAttendance);
                });
              }
            }
          }
        }
      }
      data.splice(0, 1);
    }

    function getMainStudAttendanceAttributes(studAttendance, newStudAttendance, studRecord) {
      newStudAttendance.name = studRecord.name;
      newStudAttendance.aseEchelon = studAttendance.aseEchelon == null ? "Sem escalão" : studAttendance.aseEchelon;
      newStudAttendance.studentID = studAttendance.studentID;
      newStudAttendance.parsedEducationLevel =
        studAttendance.parsedEducationLevel == "S/ nível ensino" ? null : studAttendance.parsedEducationLevel;
      newStudAttendance.monthlyPaymentWithoutExemption =
        studAttendance.monthlyPaymentWithoutExemption == null ? 0 : studAttendance.monthlyPaymentWithoutExemption;
      newStudAttendance.monthlyPayment = studAttendance.monthlyPayment == null ? 0 : studAttendance.monthlyPayment;
      newStudAttendance.monthlyTotalPayment =
        studAttendance.monthlyTotalPayment == null ? 0 : studAttendance.monthlyTotalPayment;
      newStudAttendance.monthlyTotal = studAttendance.monthlyTotal == null ? 0 : studAttendance.monthlyTotal;
      newStudAttendance.monthlyReservationsTotal =
        studAttendance.monthlyReservationsTotal == null ? 0 : studAttendance.monthlyReservationsTotal;
      if (studAttendance.situation != null) {
        newStudAttendance.situation = studAttendance.situation;
      }
      if (studAttendance.manualSituation != null) {
        newStudAttendance.situation =
          newStudAttendance.situation == null
            ? studAttendance.manualSituation
            : newStudAttendance.situation + "; " + studAttendance.manualSituation;
      }

      newStudAttendance.splitFlag = true;

      if (newStudAttendance.createdBy != null) {
        delete newStudAttendance.createdBy;
      }
      if (newStudAttendance.modifiedBy != null) {
        delete newStudAttendance.modifiedBy;
      }
      if (newStudAttendance.studentAttendanceRecords != null) {
        delete newStudAttendance.studentAttendanceRecords;
      }
    }

    return data;
  },
  vfxTransportsInfoSplit: function vfxTransportsInfoSplit(scope, data) {
    let distTicketsFlag = false;
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      /* if (distTicketsFlag) {
        break;
      } */
      if (el.transportInfo && el.transportInfo.length > 0) {
        for (let k = 0; k < el.transportInfo.length; k++) {
          let transpInfo = el.transportInfo[k];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.transportInfo = [];
          newElement.transportInfo.push(transpInfo);
          for (let j = 0; j < el.transportInfo.length; j++) {
            let transpInfo2 = el.transportInfo[j];
            if (j != k) {
              newElement.transportInfo.push(transpInfo2);
            }
          }
          /* distTicketsFlag = true; */
          data.unshift(newElement);
          i++;
          /* el.transportInfo.splice(0, 1); */
        }
      }
      data.splice(i, 1);
    }
    return data;
  },
  vfxTransportsOnlyActSitFilter: function vfxTransportsOnlyActSitFilter(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.situation != "Ativo" || el.studentSituation != "Ativo") {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
  aecKSTKSplitRecords: function aecKSTKSplitRecords(scope, data) {
    let aecs = scope.getFromTableData("KSTK_AEC_Acts");
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.aec && el.aec.length > 0 && el.situation == "Frequenta") {
        for (let k = 0; k < el.aec.length; k++) {
          let studAEC = el.aec[k];
          let newElement = JSON.parse(JSON.stringify(el));
          cleanNewElement(newElement);
          let parsedAEC = aecs.filter((el) => el.id == studAEC)[0];
          if (parsedAEC != null) {
            newElement.parsedAEC = parsedAEC.aec;
          } else {
            newElement.parsedAEC = "Sem AEC";
          }
          newElement.splitFlag = true;

          data.push(newElement);
        }
      }
      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  kstkNonTeachingStaffManagementControlFilterRecords: function kstkNonTeachingStaffManagementControlFilterRecords(
    scope,
    data
  ) {
    let monthInfoToSplit = [];

    let refDateMs = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-01").getTime();
    let refYear = new Date().getFullYear();
    let refMonth = new Date().getMonth() + 1;
    let year = refYear;
    let month = JSON.parse(JSON.stringify(refMonth));
    for (let m = 1; m < 25; m++) {
      monthInfoToSplit.push({
        civilYear: new Date(refDateMs).getFullYear(),
        month: getMonthFromDateMs(refDateMs),
        date: refDateMs,
      });
      month = refMonth - m;
      if (month % 12 == 0) {
        year = year - 1;
        month = 12;
      } else {
        if (month > -12) {
          month = 12 + month;
        } else {
          month = 24 + month;
        }
      }
      if (month > 12) {
        month = month - 12;
      }
      refDateMs = new Date(year + "-" + Math.abs(month) + "-01").getTime();
    }

    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitByMonthFlag) {
        break;
      }

      if (el.history != null && Array.isArray(el.history) && el.history.length > 0) {
        pushNewElsByMonth(el);
      } else {
        if (el.situation == "Ativo" && (el.allocation == "Sala" || el.allocation == "Apoio Educativo")) {
          pushNewElsByMonth(el);
        }
      }

      data.splice(i, 1);
    }

    function pushNewElsByMonth(el) {
      for (let k = 0; k < monthInfoToSplit.length; k++) {
        let monthInfo = monthInfoToSplit[k];
        let activeInMonthStaffFlag = false;
        // Remove duplicate values when
        if (
          el.year != new Date(monthInfo.date).getFullYear() &&
          Number(el.year) + 1 != Number(new Date(monthInfo.date).getFullYear())
        ) {
          continue;
        }
        if (el.year == new Date(monthInfo.date).getFullYear() && new Date(monthInfo.date).getMonth() < 8) {
          continue;
        }
        if (el.year != new Date(monthInfo.date).getFullYear() && new Date(monthInfo.date).getMonth() > 7) {
          continue;
        }
        // Check if staff had an active contract on that month
        if (
          el.contractStartDateMs != null &&
          el.contractEndDateMs != null &&
          ((monthInfo.date >= el.contractStartDateMs && monthInfo.date <= el.contractEndDateMs) ||
            (new Date(monthInfo.date).getFullYear() == new Date(el.contractStartDateMs).getFullYear() &&
              new Date(monthInfo.date).getMonth() == new Date(el.contractStartDateMs).getMonth()) ||
            (new Date(monthInfo.date).getFullYear() == new Date(el.contractEndDateMs).getFullYear() &&
              new Date(monthInfo.date).getMonth() == new Date(el.contractEndDateMs).getMonth()))
        ) {
          activeInMonthStaffFlag = true;
        } else if (
          el.contractStartDateMs != null &&
          el.contractEndDateMs == null &&
          (monthInfo.date >= el.contractStartDateMs ||
            (new Date(monthInfo.date).getFullYear() == new Date(el.contractStartDateMs).getFullYear() &&
              new Date(monthInfo.date).getMonth() == new Date(el.contractStartDateMs).getMonth()))
        ) {
          activeInMonthStaffFlag = true;
        } else if (
          el.contractStartDateMs == null &&
          el.contractEndDateMs != null &&
          (monthInfo.date <= el.contractEndDateMs ||
            (new Date(monthInfo.date).getFullYear() == new Date(el.contractEndDateMs).getFullYear() &&
              new Date(monthInfo.date).getMonth() == new Date(el.contractEndDateMs).getMonth()))
        ) {
          activeInMonthStaffFlag = true;
        }
        if (!activeInMonthStaffFlag && (el.contractStartDateMs != null || el.contractEndDateMs != null)) {
          continue;
        }
        if (el.history && el.history.length > 0) {
          let insertedElFlag = false;
          let historyCivilYearMonths = [];
          for (let j = 0; j < el.history.length; j++) {
            let hEl = el.history[j];
            // Skip invalid history elements
            if (hEl.year != hEl.civilYear && ["setembro", "outubro", "novembro", "dezembro"].indexOf(hEl.month) != -1) {
              continue;
            }
            historyCivilYearMonths.push(hEl.civilYear + hEl.month);
            if (hEl.year == new Date(monthInfo.date).getFullYear() && new Date(monthInfo.date).getMonth() < 8) {
              continue;
            }
            if (hEl.year != new Date(monthInfo.date).getFullYear() && new Date(monthInfo.date).getMonth() > 7) {
              continue;
            }
            if (
              hEl.civilYear == monthInfo.civilYear &&
              hEl.month == monthInfo.month &&
              hEl.situation == "Ativo" &&
              (scope.activeTabName == "Resultado de Exploração" ||
                (scope.activeTabName != "Consulta Custos Indiretos" &&
                  (hEl.allocation == "Sala" || hEl.allocation == "Apoio Educativo")) ||
                (scope.activeTabName == "Consulta Custos Indiretos" &&
                  (hEl.allocation == "CEI" || (hEl.allocation == "Escritório" && hEl.indirectCostsPercentage != null))))
            ) {
              let newEl = JSON.parse(JSON.stringify(el));
              newEl.year = hEl.year;
              newEl.civilYear = hEl.civilYear;
              newEl.month = hEl.month;
              newEl.historyDateMs = hEl.historyDateMs;
              newEl.situation = hEl.situation;
              if (hEl.cluster != null) {
                newEl.cluster = hEl.cluster;
              }
              if (hEl.school != null) {
                newEl.school = hEl.school;
              }
              if (hEl.educationLevel != null) {
                newEl.educationLevel = hEl.educationLevel;
              }
              if (hEl.codeIGEFE != null) {
                newEl.codeIGEFE = hEl.codeIGEFE;
              }
              newEl.excludedRatio = hEl.excludedRatio;
              newEl.allocation = hEl.allocation;
              newEl.professionalBond = hEl.professionalBond;
              newEl.contractStartDateMs = hEl.contractStartDateMs;
              newEl.contractEndDateMs = hEl.contractEndDateMs;
              newEl.function = hEl.function;
              newEl.category = hEl.category;
              newEl.echelonPosition = hEl.echelonPosition;
              newEl.netMonthRemuneration = hEl.netMonthRemuneration;
              newEl.remunerationIndex = hEl.remunerationIndex;
              newEl.indirectCostsPercentage = hEl.indirectCostsPercentage;
              insertedElFlag = true;
              filterManagementControlFields(newEl);
              newEl.splitByMonthFlag = true;
              data.push(newEl);
            }
          }
          if (!insertedElFlag && historyCivilYearMonths.indexOf(monthInfo.civilYear + monthInfo.month) == -1) {
            let newEl = JSON.parse(JSON.stringify(el));
            if (newEl.situation == "Ativo" && (newEl.allocation == "Sala" || newEl.allocation == "Apoio Educativo")) {
              newEl.civilYear = monthInfo.civilYear;
              newEl.month = monthInfo.month;
              filterManagementControlFields(newEl);
              newEl.splitByMonthFlag = true;
              data.push(newEl);
            }
          }
        } else {
          let newEl = JSON.parse(JSON.stringify(el));
          if (newEl.situation == "Ativo" && (newEl.allocation == "Sala" || newEl.allocation == "Apoio Educativo")) {
            newEl.civilYear = monthInfo.civilYear;
            newEl.month = monthInfo.month;
            filterManagementControlFields(newEl);
            newEl.splitByMonthFlag = true;
            data.push(newEl);
          }
        }
      }
    }

    function filterManagementControlFields(el) {
      if (el.expectedRevenue != null && Array.isArray(el.expectedRevenue) && el.expectedRevenue.length > 0) {
        el.expectedRevenue = el.expectedRevenue.filter(
          (cFEl) => el.civilYear == cFEl.civilYear && el.month == cFEl.month.toLowerCase()
        );
      }
      if (el.costs != null && Array.isArray(el.costs) && el.costs.length > 0) {
        el.costs = el.costs.filter((cFEl) => el.civilYear == cFEl.civilYear && el.month == cFEl.month.toLowerCase());
      }
      if (el.insuranceCosts != null && Array.isArray(el.insuranceCosts) && el.insuranceCosts.length > 0) {
        el.insuranceCosts = el.insuranceCosts.filter(
          (cFEl) => el.civilYear == cFEl.civilYear && el.month == cFEl.month.toLowerCase()
        );
      }
    }
  },
  kstkNonTeachingStaffExpectedRevenueSplitRecords: function kstkNonTeachingStaffExpectedRevenueSplitRecords(
    scope,
    data
  ) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.expectedRevenue && el.expectedRevenue.length > 0) {
        el.expectedRevenue.forEach((expectedRev) => {
          let newEl = JSON.parse(JSON.stringify(el));
          newEl.year = el.year;
          newEl.civilYear = expectedRev.civilYear;
          newEl.month = getMonthWithNumberBefore(expectedRev.month);
          newEl.expectedRevNetMonthRemuneration = expectedRev.expectedRevNetMonthRemuneration;
          newEl.expectedRevMealAllowance = expectedRev.expectedRevMealAllowance;
          newEl.expectedRevEmployerCharges = expectedRev.expectedRevEmployerCharges;
          newEl.expectedRevFaultAllowance = expectedRev.expectedRevFaultAllowance;
          newEl.expectedRevHolidaysChristAllowance = expectedRev.expectedRevHolidaysChristAllowance;
          let importedHolidayAllowanceFromCosts = false;
          if (
            el.costs &&
            el.costs.length > 0 &&
            (newEl.expectedRevHolidaysChristAllowance == null || newEl.expectedRevHolidaysChristAllowance == "")
          ) {
            let filteredMatchingCost = el.costs.filter(
              (c) => c.civilYear == newEl.civilYear && c.month == expectedRev.month
            );
            if (filteredMatchingCost.length > 0) {
              let matchingCost = filteredMatchingCost[0];
              if (
                matchingCost.costsHolidaysChristAllowance != null &&
                !isNaN(Number(matchingCost.costsHolidaysChristAllowance))
              ) {
                newEl.expectedRevHolidaysChristAllowance = matchingCost.costsHolidaysChristAllowance;
                importedHolidayAllowanceFromCosts = true;
              }
            }
          }
          newEl.expectedRevTotal = !importedHolidayAllowanceFromCosts
            ? expectedRev.expectedRevTotal
            : Math.round((expectedRev.expectedRevTotal + newEl.expectedRevHolidaysChristAllowance) * 100) / 100;
          newEl.splitFlag = true;
          data.push(newEl);
        });
      }
      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffCostsSplitRecords: function kstkNonTeachingStaffCostsSplitRecords(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.costs && el.costs.length > 0) {
        el.costs.forEach((cost) => {
          let newEl = JSON.parse(JSON.stringify(el));
          newEl.year = el.year;
          newEl.civilYear = cost.civilYear;
          newEl.month = getMonthWithNumberBefore(cost.month);
          newEl.costsCareer = cost.costsCareer;
          newEl.costsCategory = cost.costsCategory;
          newEl.costsNetMonthRemuneration = cost.costsNetMonthRemuneration;
          newEl.costsFaults = cost.costsFaults;
          newEl.costsHolidaysChristAllowance =
            cost.costsHolidaysChristAllowance == null ? 0 : cost.costsHolidaysChristAllowance;
          newEl.costsMealAllowance = cost.costsMealAllowance;
          newEl.costsRepresentationCosts = cost.costsRepresentationCosts;
          newEl.costsFaultAllowance = cost.costsFaultAllowance;
          newEl.costsCompContract = cost.costsCompContract;
          newEl.costsExtraWork = cost.costsExtraWork;
          newEl.costsPension = cost.costsPension;
          newEl.costsRemunAdjustments = cost.costsRemunAdjustments;
          newEl.costsOtherAllowances = cost.costsOtherAllowances;
          newEl.costsOtherDiscounts = cost.costsOtherDiscounts;
          newEl.costsCGA = cost.costsCGA;
          newEl.costsCRSS = cost.costsCRSS;
          newEl.costsTotal = cost.costsTotal;
          newEl.splitFlag = true;
          data.push(newEl);
        });
      }
      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffCostsSplitSpreadRecords: function kstkNonTeachingStaffCostsSplitSpreadRecords(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.costs && el.costs.length > 0) {
        el.costs.forEach((cost) => {
          let newEl = JSON.parse(JSON.stringify(el));
          newEl.year = el.year;
          newEl.civilYear = cost.civilYear;
          newEl.month = getMonthWithNumberBefore(cost.month);
          newEl.costsCareer = cost.costsCareer;
          newEl.costsCategory = cost.costsCategory;

          let newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Venc. Base";
          newSubEl.value = cost.costsNetMonthRemuneration;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Faltas";
          if (cost.costsFaults != null) {
            newSubEl.value = cost.costsFaults * -1;
          }
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Subs. Férias/Natal";
          newSubEl.value = cost.costsHolidaysChristAllowance == null ? 0 : cost.costsHolidaysChristAllowance;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Subs. Refeição";
          newSubEl.value = cost.costsMealAllowance;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Representação";
          newSubEl.value = cost.costsRepresentationCosts;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Abono Falhas";
          newSubEl.value = cost.costsFaultAllowance;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Comp. Ter. Contrato";
          newSubEl.value = cost.costsCompContract;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Trabalho Extraord.";
          newSubEl.value = cost.costsExtraWork;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Pensão";
          newSubEl.value = cost.costsPension;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Acertos Remuneratórios";
          newSubEl.value = cost.costsRemunAdjustments;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Outros Abonos";
          newSubEl.value = cost.costsOtherAllowances;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Outros Descontos";
          newSubEl.value = cost.costsOtherDiscounts;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "CGA";
          newSubEl.value = cost.costsCGA;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "CRSS";
          newSubEl.value = cost.costsCRSS;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          /* newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Total";
          newSubEl.value = cost.costsTotal;
          newSubEl.splitFlag = true;
          data.push(newSubEl); */
        });
      }
      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffInsuranceCostsSplitRecords: function kstkNonTeachingStaffInsuranceCostsSplitRecords(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.insuranceCosts && el.insuranceCosts.length > 0) {
        el.insuranceCosts.forEach((inCosts) => {
          let newEl = JSON.parse(JSON.stringify(el));
          newEl.year = el.year;
          newEl.civilYear = inCosts.civilYear;
          newEl.month = getMonthWithNumberBefore(inCosts.month);
          newEl.insuranceCharge = inCosts.insuranceCharge;
          newEl.splitFlag = true;
          data.push(newEl);
        });
      }
      data.splice(0, 1);
    }
  },
  getParsedLogs: function getParsedLogs(scope, data) {
    let refDate = new Date().getTime();
    data.map((el) => {
      if (
        el.who.indexOf("carolina.frias") != -1 ||
        el.who.indexOf("jose.veiga") != -1 ||
        el.who.indexOf("andre.veiga") != -1 ||
        el.who.indexOf("pedro.veiga") != -1 ||
        el.who.indexOf("admin@kstk.pt") != -1
      ) {
        el.orgName = "KSTK";
      } else {
        el.orgName = getOrgName(el.organization);
      }

      let elDateMs = new Date(el.date).getTime();

      // Last 24 hours
      if (elDateMs > refDate - 24 * 60 * 60 * 1000) {
        el.last24Hours = "Sim";
      }

      // Last 7 days
      if (elDateMs > refDate - 7 * 24 * 60 * 60 * 1000) {
        el.last7Days = "Sim";
      }

      // Last 30 days
      if (elDateMs > refDate - 30 * 24 * 60 * 60 * 1000) {
        el.last30Days = "Sim";
      }

      return el;
    });

    function getOrgName(orgId) {
      var filteredOrg = scope.getFromTableData("organizations").filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].name;
      } else {
        return "KSTK";
      }
    }
  },
  cmsSchoolMilkProvisionDeliveredMilkSplit: function cmsSchoolMilkProvisionDeliveredMilkSplit(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.splitFlag) {
        break;
      }
      if (el.deliveredMilk != null && Array.isArray(el.deliveredMilk) && el.deliveredMilk.length > 0) {
        for (let w = 0; w < el.deliveredMilk.length; w++) {
          let delivery = el.deliveredMilk[w];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.deliveryDesc = delivery.deliveryDesc;
          newElement.deliveryDateMs = delivery.deliveryDateMs;
          newElement.deliveredPlainMilkNumberPacks = delivery.deliveredPlainMilkNumberPacks;
          newElement.deliveredPlainMilkNumberBoxes = delivery.deliveredPlainMilkNumberBoxes;
          newElement.deliveredChocolateMilkNumberPacks = delivery.deliveredChocolateMilkNumberPacks;
          newElement.deliveredChocolateMilkNumberBoxes = delivery.deliveredChocolateMilkNumberBoxes;
          newElement.deliveredLactoseFreeMilkNumberPacks = delivery.deliveredLactoseFreeMilkNumberPacks;
          newElement.deliveredLactoseFreeMilkNumberBoxes = delivery.deliveredLactoseFreeMilkNumberBoxes;
          newElement.invoiceNumber = delivery.invoiceNumber;
          newElement.invoiceValue = delivery.invoiceValue;
          newElement.splitFlag = true;
          cleanNewElement(newElement);
          data.push(newElement);
        }
      }
      data.splice(i, 1);
    }
    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  cmsSchoolMilkProvisionInvoiceSplit: function cmsSchoolMilkProvisionInvoiceSplit(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.splitFlag) {
        break;
      }
      if (el.deliveredMilk != null && Array.isArray(el.deliveredMilk) && el.deliveredMilk.length > 0) {
        for (let w = 0; w < el.deliveredMilk.length; w++) {
          let delivery = el.deliveredMilk[w];
          if (delivery.invoiceNumber != null && delivery.invoiceValue != null && delivery.deliveryDateMs != null) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.invoiceNumber = delivery.invoiceNumber;
            newElement.invoiceValue = delivery.invoiceValue;
            newElement.invoiceDate = delivery.deliveryDateMs;
            newElement.splitFlag = true;
            cleanNewElement(newElement);
            data.push(newElement);
          }
        }
      }
      if (el.invoices != null && Array.isArray(el.invoices) && el.invoices.length > 0) {
        for (let w = 0; w < el.invoices.length; w++) {
          let invoice = el.invoices[w];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.invoiceNumber = invoice.invoiceNumber;
          newElement.invoiceValue = invoice.invoiceValue;
          newElement.invoiceDate = invoice.invoiceDate;
          newElement.splitFlag = true;
          cleanNewElement(newElement);
          data.push(newElement);
        }
      }
      data.splice(i, 1);
    }
    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  kstkSocialAssessmentDomainFilter: function kstkSocialAssessmentDomainFilter(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.domainDesc != scope.activeTabName) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
  ptSplitAESchoolRecord: function ptSplitAESchoolRecord(scope, data, finalEvalFlag) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (finalEvalFlag && el.excludedFinalEvalFlag && el.excludedFinalEvalFlag.length > 0) {
        data.splice(i, 1);
        continue;
      }

      if (el.studentEvaluations) {
        for (let k = 0; k < el.studentEvaluations.length; k++) {
          let studEval = el.studentEvaluations[k];

          if (studEval.studEvalExcludedFinalEvalFlag && studEval.studEvalExcludedFinalEvalFlag.length > 0) {
            continue;
          }

          if (studEval.essentialLearningEvaluation != null && studEval.essentialLearningEvaluation.length > 0) {
            if (studEval.essentialLearningEvaluation && studEval.qualitativeEvalMap == null) {
              for (let w = 0; w < studEval.essentialLearningEvaluation.length; w++) {
                let newElement = JSON.parse(JSON.stringify(el));
                let essenLearn = studEval.essentialLearningEvaluation[w];

                newElement.essentialLearning = essenLearn.essentialLearning;
                if (essenLearn.evaluation == null) {
                  continue;
                }
                newElement.essentialLearningEval = essenLearn.evaluation;
                newElement.name = studEval.studentID;
                if (studEval.originalClass) {
                  newElement.class = studEval.originalClass;
                }
                if (studEval.student) {
                  newElement.classOrder = studEval.student.classOrder;
                } else {
                  newElement.classOrder = studEval.studentClassOrder;
                }
                if (newElement.module != null) {
                  newElement.parsedModule = scope.parseValue(newElement.module, "module", "Class_Plan_Prof_Modules");
                }
                newElement.splitFlag = true;
                cleanNewElement(newElement);
                data.push(newElement);
              }
            }
          }
          let studEvalWithQuantitativeEvals = false;
          if (
            studEval.essentialLearningQuestionEvaluation != null &&
            studEval.essentialLearningQuestionEvaluation.length > 0
          ) {
            for (const essenLearn in studEval.essenLearnFinalEvalMap) {
              if (studEval.essenLearnFinalEvalMap.hasOwnProperty(essenLearn)) {
                const essenLearnEval = studEval.essenLearnFinalEvalMap[essenLearn];
                let newElement = JSON.parse(JSON.stringify(el));

                newElement.essentialLearning = essenLearnEval.essentialLearning;
                if (essenLearnEval.evaluation == null) {
                  continue;
                }
                studEvalWithQuantitativeEvals = true;
                if (finalEvalFlag) {
                  newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation * 100) / 100;
                } else {
                  newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation);
                }
                newElement.name = studEval.studentID;
                if (studEval.originalClass) {
                  newElement.class = studEval.originalClass;
                }
                if (studEval.student) {
                  newElement.classOrder = studEval.student.classOrder;
                } else {
                  newElement.classOrder = studEval.studentClassOrder;
                }
                if (newElement.module != null) {
                  newElement.parsedModule = scope.parseValue(newElement.module, "module", "Class_Plan_Prof_Modules");
                }
                newElement.splitFlag = true;
                cleanNewElement(newElement);
                data.push(newElement);
              }
            }
          }
          if (studEval.qualitativeEvalMap != null && !studEvalWithQuantitativeEvals) {
            for (const essenLearn in studEval.qualitativeEvalMap) {
              if (studEval.qualitativeEvalMap.hasOwnProperty(essenLearn)) {
                let essenLearnEvals = studEval.qualitativeEvalMap[essenLearn];
                if (
                  essenLearnEvals == null ||
                  (studEval.completeQualitativeEvals != null && studEval.completeQualitativeEvals == false)
                ) {
                  continue;
                }
                if (finalEvalFlag) {
                  essenLearnEvals.map(function (essenLearnEval) {
                    let newElement = JSON.parse(JSON.stringify(el));
                    newElement.essentialLearningEval = essenLearnEval.essentialLearningEval;
                    newElement.essentialLearning = essenLearn;
                    newElement.name = studEval.studentID;
                    if (studEval.originalClass) {
                      newElement.class = studEval.originalClass;
                    }
                    if (studEval.student) {
                      newElement.classOrder = studEval.student.classOrder;
                    } else {
                      newElement.classOrder = studEval.studentClassOrder;
                    }
                    if (newElement.module != null) {
                      newElement.parsedModule = scope.parseValue(
                        newElement.module,
                        "module",
                        "Class_Plan_Prof_Modules"
                      );
                    }
                    newElement.splitFlag = true;
                    cleanNewElement(newElement);
                    data.push(newElement);
                  });
                } else {
                  let newElement = JSON.parse(JSON.stringify(el));
                  newElement.essentialLearningEval = "";
                  essenLearnEvals.map(function (essenLearnEval) {
                    if (newElement.essentialLearningEval == "") {
                      newElement.essentialLearningEval += essenLearnEval.essentialLearningEval;
                    } else {
                      newElement.essentialLearningEval += ", " + essenLearnEval.essentialLearningEval;
                    }
                  });
                  newElement.essentialLearning = essenLearn;
                  newElement.name = studEval.studentID;
                  if (studEval.originalClass) {
                    newElement.class = studEval.originalClass;
                  }
                  if (studEval.student) {
                    newElement.classOrder = studEval.student.classOrder;
                  } else {
                    newElement.classOrder = studEval.studentClassOrder;
                  }
                  if (newElement.module != null) {
                    newElement.parsedModule = scope.parseValue(newElement.module, "module", "Class_Plan_Prof_Modules");
                  }
                  newElement.splitFlag = true;
                  cleanNewElement(newElement);
                  data.push(newElement);
                }
              }
            }
          }
        }
      }

      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.studentEvaluations != null) {
        delete element.studentEvaluations;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    let essenLearnMap = {};

    for (let j = 0; j < data.length; j++) {
      let el = data[j];

      //Parse qualitative eval if necessary
      let parsedQuantitativeEval;
      if (typeof el.essentialLearningEval == "string" && el.evalInstrumentQualitativeLevels) {
        let parsedEvalInstrumentQualitativeLevels = JSON.parse(el.evalInstrumentQualitativeLevels);
        if (
          parsedEvalInstrumentQualitativeLevels.qualitativeLevels &&
          Array.isArray(parsedEvalInstrumentQualitativeLevels.qualitativeLevels) &&
          parsedEvalInstrumentQualitativeLevels.qualitativeLevels.length > 0
        ) {
          let quantitativeEval = classPlanGetQuantitativeEvalsFromQualitative(
            new Array(el.essentialLearningEval),
            parsedEvalInstrumentQualitativeLevels.qualitativeLevels
          );
          if (!isNaN(Number(quantitativeEval))) {
            parsedQuantitativeEval = quantitativeEval;
          } else {
            continue;
          }
        } else {
          continue;
        }
      }

      if (essenLearnMap[el.name] == null) {
        essenLearnMap[el.name] = {};
      }
      if (essenLearnMap[el.name][el.essentialLearning] == null) {
        essenLearnMap[el.name][el.essentialLearning] = {};
      }
      if (essenLearnMap[el.name][el.essentialLearning].essenLearnTotal != null) {
        if (parsedQuantitativeEval != null) {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += parsedQuantitativeEval;
        } else {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += el.essentialLearningEval;
        }
        essenLearnMap[el.name][el.essentialLearning].totalEvals += 1;
      } else {
        if (parsedQuantitativeEval != null) {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = parsedQuantitativeEval;
        } else {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
        }
        essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
      }
    }

    for (let w = 0; w < data.length; w++) {
      let el = data[w];
      for (const studName in essenLearnMap) {
        if (essenLearnMap.hasOwnProperty(studName)) {
          let essLearn = essenLearnMap[studName];
          if (el.name == studName && essLearn[el.essentialLearning] != null) {
            if (essLearn[el.essentialLearning].essenLearnTotal != null) {
              if (finalEvalFlag) {
                el.essenLearnAvg =
                  Math.round(
                    (essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals) * 100
                  ) / 100;
              } else {
                el.essenLearnAvg = Math.round(
                  essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals
                );
              }
            }
          }
        }
      }
    }

    return data;
  },
  ptSplitFinalEvalsSchoolRecord: function ptSplitFinalEvalsSchoolRecord(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentFinalCritEvaluations && el.studentFinalCritEvaluations.length > 0) {
        for (let k = 0; k < el.studentFinalCritEvaluations.length; k++) {
          let studEval = el.studentFinalCritEvaluations[k];

          let newElement = JSON.parse(JSON.stringify(el));

          newElement.name = studEval.name;
          newElement.studentID = studEval.studentID;
          if (studEval.originalClass) {
            newElement.class = studEval.originalClass;
          }
          newElement.crit = studEval.essenLearnCrit;
          newElement.student = studEval.student;
          newElement.studCritEval = true;
          newElement.studAECritEval = true;

          if (studEval.finalEssenLearnCritEvalGrade != null) {
            newElement.evalCrit = Math.round(Number(studEval.finalEssenLearnCritEvalGrade));
          } else {
            newElement.evalCrit = Math.round(Number(studEval.autoFinalEssenLearnCritEvalGrade));
          }

          if (studEval.finalGrade == null) {
            if (studEval.autoFinalGrade != null) {
              /* newElement.finalEval = studEval.autoFinalGrade; */
              newElement.splitFlag = true;
            }
          } else {
            /* newElement.finalEval = studEval.finalGrade; */
            newElement.splitFlag = true;
          }

          /* if (studEval.finalEvalLevel != null) {
            newElement.finalEvalLevel = studEval.finalEvalLevel;
          } */

          if (newElement.splitFlag) {
            if (newElement.student != null) {
              delete newElement.student;
            }
            if (newElement.studentFinalCritEvaluations != null) {
              delete newElement.studentFinalCritEvaluations;
            }
            if (newElement.subjectCriteria != null) {
              delete newElement.subjectCriteria;
            }
            if (newElement.modifiedBy != null) {
              delete newElement.modifiedBy;
            }
            /* data.push(newElement); */

            for (let j = 0; j < studEval.studentFinalEssenLearnCritEvals.length; j++) {
              let essenLearnStudEval = studEval.studentFinalEssenLearnCritEvals[j];
              let newElementEssenLearnEval = JSON.parse(JSON.stringify(el));
              newElementEssenLearnEval.name = studEval.name;
              newElementEssenLearnEval.studentID = studEval.studentID;
              if (studEval.originalClass) {
                newElementEssenLearnEval.class = studEval.originalClass;
              }
              newElementEssenLearnEval.student = studEval.student;
              newElementEssenLearnEval.crit = studEval.essenLearnCrit;
              newElementEssenLearnEval.essenLearn = essenLearnStudEval.parsedEssentialLearning.split(" ");
              newElementEssenLearnEval.essenLearn.pop();
              newElementEssenLearnEval.essenLearn = newElementEssenLearnEval.essenLearn.join(" ");
              /* if (
                essenLearnStudEval.newEssentialLearningWeight != null &&
                essenLearnStudEval.newEssentialLearningWeight != ""
              ) {
                newElementEssenLearnEval.essenLearn =
                  essenLearnStudEval.parsedEssentialLearning +
                  " -> (" +
                  essenLearnStudEval.newEssentialLearningWeight +
                  ")";
              } else {
                newElementEssenLearnEval.essenLearn = essenLearnStudEval.parsedEssentialLearning;
              } */
              if (essenLearnStudEval.learningProfile != null) {
                newElementEssenLearnEval.learningProfile = essenLearnStudEval.learningProfile;
              }
              if (
                essenLearnStudEval.essentialLearningAvg != null &&
                !isNaN(Number(essenLearnStudEval.essentialLearningAvg))
              ) {
                newElementEssenLearnEval.evalCrit = Math.round(Number(essenLearnStudEval.essentialLearningAvg));
              }
              newElementEssenLearnEval.studAEEval = true;
              newElementEssenLearnEval.splitFlag = true;

              if (newElementEssenLearnEval.student != null) {
                delete newElementEssenLearnEval.student;
              }
              if (newElementEssenLearnEval.studentFinalCritEvaluations != null) {
                delete newElementEssenLearnEval.studentFinalCritEvaluations;
              }
              if (newElementEssenLearnEval.subjectCriteria != null) {
                delete newElementEssenLearnEval.subjectCriteria;
              }
              if (newElementEssenLearnEval.modifiedBy != null) {
                delete newElementEssenLearnEval.modifiedBy;
              }
              data.push(newElementEssenLearnEval);
            }
          }

          for (let w = 0; w < studEval.studentFinalCritEvals.length; w++) {
            let newElement = JSON.parse(JSON.stringify(el));
            let crit = studEval.studentFinalCritEvals[w];

            newElement.name = studEval.name;
            newElement.studentID = studEval.studentID;
            if (studEval.originalClass) {
              newElement.class = studEval.originalClass;
            }
            newElement.crit = crit.criterion;
            newElement.student = studEval.student;
            newElement.studCritEval = true;

            if (crit.autoCritEvalAvg != null) {
              newElement.evalCrit = Math.round(Number(crit.autoCritEvalAvg));
            } else {
              newElement.evalCrit = Math.round(Number(crit.evaluation));
            }

            /* if (studEval.finalEvalLevel != null) {
              newElement.finalEvalLevel = studEval.finalEvalLevel;
            } */

            if (newElement.student != null) {
              delete newElement.student;
            }
            if (newElement.studentFinalCritEvaluations != null) {
              delete newElement.studentFinalCritEvaluations;
            }
            if (newElement.subjectCriteria != null) {
              delete newElement.subjectCriteria;
            }
            if (newElement.modifiedBy != null) {
              delete newElement.modifiedBy;
            }

            if (studEval.finalGrade == null) {
              if (studEval.autoFinalGrade != null) {
                /* newElement.finalEval = studEval.autoFinalGrade; */
                newElement.splitFlag = true;
                data.push(newElement);
              }
            } else {
              /* newElement.finalEval = studEval.finalGrade; */
              newElement.splitFlag = true;
              data.push(newElement);
            }
          }

          if (newElement.splitFlag) {
            let newElementStudFinalEval = JSON.parse(JSON.stringify(el));
            newElementStudFinalEval.name = studEval.name;
            newElementStudFinalEval.studentID = studEval.studentID;
            if (studEval.originalClass) {
              newElementStudFinalEval.class = studEval.originalClass;
            }
            newElementStudFinalEval.student = studEval.student;
            if (studEval.student) {
              newElementStudFinalEval.classOrder = studEval.student.classOrder;
            } else {
              newElementStudFinalEval.classOrder = studEval.studentClassOrder;
            }
            if (studEval.profRecoveryInstrEvalsFlag) {
              newElementStudFinalEval.profRecoveryInstrEvalsFlag = studEval.profRecoveryInstrEvalsFlag;
            }

            if (studEval.finalGrade == null) {
              if (studEval.autoFinalGrade != null) {
                newElementStudFinalEval.finalEval = studEval.autoFinalGrade;
                newElementStudFinalEval.splitFlag = true;
              }
            } else {
              newElementStudFinalEval.finalEval = studEval.finalGrade;
              newElementStudFinalEval.splitFlag = true;
            }

            if (studEval.feedbackStud != null) {
              newElementStudFinalEval.feedbackStud = studEval.feedbackStud;
            }

            if (studEval.finalEvalLevel != null) {
              newElementStudFinalEval.finalEvalLevel = studEval.finalEvalLevel;
            }
            newElementStudFinalEval.studFinalEval = true;

            if (newElementStudFinalEval.student != null) {
              delete newElementStudFinalEval.student;
            }
            if (newElementStudFinalEval.studentFinalCritEvaluations != null) {
              delete newElementStudFinalEval.studentFinalCritEvaluations;
            }
            if (newElementStudFinalEval.subjectCriteria != null) {
              delete newElementStudFinalEval.subjectCriteria;
            }

            if (newElementStudFinalEval.modifiedBy != null) {
              delete newElementStudFinalEval.modifiedBy;
            }
            data.push(newElementStudFinalEval);
          }
        }
      }

      data.splice(0, 1);
    }

    return data;
  },
  ptQuestionEvalFilter: function ptInstrEvalFilter(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (
        el.studentEvaluations &&
        el.studentEvaluations.length > 0 &&
        el.studentEvaluations[0].essentialLearningEvaluation &&
        el.studentEvaluations[0].essentialLearningEvaluation.length > 0
      ) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
  ptSplitInstrQuestionSchoolRecord: function ptSplitInstrQuestionSchoolRecord(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentEvaluations) {
        for (let k = 0; k < el.studentEvaluations.length; k++) {
          let studEval = el.studentEvaluations[k];
          let alreadySplitEvalsFlag = false;
          for (const essenLearn in studEval.qualitativeEvalMap) {
            if (studEval.qualitativeEvalMap.hasOwnProperty(essenLearn)) {
              let essenLearnEvals = studEval.qualitativeEvalMap[essenLearn];
              if (
                essenLearnEvals == null ||
                (studEval.completeQualitativeEvals != null && studEval.completeQualitativeEvals == false)
              ) {
                continue;
              }
              essenLearnEvals.map(function (essenLearnEval) {
                let newElement = JSON.parse(JSON.stringify(el));
                newElement.weight = essenLearnEval.weight;
                newElement.question = essenLearnEval.question;
                newElement.questionEval = essenLearnEval.essentialLearningEval;
                newElement.essentialLearningEval = essenLearnEval.essentialLearningEval;
                newElement.essentialLearning = essenLearn;
                newElement.name = studEval.studentID;
                newElement.student = studEval.student;
                newElement.splitFlag = true;
                newElement.qualitativeEvalFlag = true;
                if (newElement.module != null) {
                  newElement.parsedModule = scope.parseValue(newElement.module, "module", "Class_Plan_Prof_Modules");
                }
                if (newElement.student != null) {
                  delete newElement.student;
                }
                if (newElement.studentEvaluations != null) {
                  delete newElement.studentEvaluations;
                }
                /* if (newElement.createdBy != null) {
                  delete newElement.createdBy;
                } */
                if (newElement.modifiedBy != null) {
                  delete newElement.modifiedBy;
                }
                data.push(newElement);
                alreadySplitEvalsFlag = true;
              });
            }
          }
          if (
            !alreadySplitEvalsFlag &&
            studEval.essentialLearningQuestionEvaluation != null &&
            studEval.essentialLearningQuestionEvaluation.length > 0
          ) {
            if (studEval.essenLearnFinalEvalMap && Object.keys(studEval.essenLearnFinalEvalMap).length > 0) {
              for (let w = 0; w < studEval.essentialLearningQuestionEvaluation.length; w++) {
                let newElement = JSON.parse(JSON.stringify(el));
                let essenLearnQuestionEval = studEval.essentialLearningQuestionEvaluation[w];

                newElement.weight = essenLearnQuestionEval.weight;
                newElement.question = essenLearnQuestionEval.question;
                newElement.questionEval = essenLearnQuestionEval.evaluation;
                newElement.essentialLearning = essenLearnQuestionEval.parsedEssentialLearning;
                newElement.performanceCrit = essenLearnQuestionEval.performanceCrit;

                if (essenLearnQuestionEval.evaluation == null) {
                  continue;
                }

                newElement.essentialLearningEval =
                  Math.round(
                    studEval.essenLearnFinalEvalMap[essenLearnQuestionEval.essentialLearning].evaluation * 100
                  ) / 100;
                //newElement.essentialLearningEval = studEval.essenLearnFinalEvalMap[essenLearnQuestionEval.essentialLearning].evaluation;

                newElement.name = studEval.studentID;
                newElement.splitFlag = true;
                newElement.quantitativeEvalFlag = true;
                if (newElement.module != null) {
                  newElement.parsedModule = scope.parseValue(newElement.module, "module", "Class_Plan_Prof_Modules");
                }

                if (newElement.student != null) {
                  delete newElement.student;
                }
                if (newElement.studentEvaluations != null) {
                  delete newElement.studentEvaluations;
                }
                /* if (newElement.createdBy != null) {
                  delete newElement.createdBy;
                } */
                if (newElement.modifiedBy != null) {
                  delete newElement.modifiedBy;
                }
                data.push(newElement);
              }
            }
          }
        }
      }

      data.splice(0, 1);
    }

    let essenLearnMap = {};

    for (let j = 0; j < data.length; j++) {
      let el = data[j];
      if (el.qualitativeLevels != null && Object.keys(el.qualitativeLevels).length > 0 && el.qualitativeEvalFlag) {
        if (essenLearnMap[el.name] == null) {
          essenLearnMap[el.name] = {};
          essenLearnMap[el.name][el.essentialLearning] = [];
          let essenQualEval = {};
          essenQualEval.totalEvals = 1;
          essenQualEval.essentialLearningEval = el.essentialLearningEval;
          essenLearnMap[el.name][el.essentialLearning].push(essenQualEval);
        } else {
          if (essenLearnMap[el.name][el.essentialLearning] == null) {
            essenLearnMap[el.name][el.essentialLearning] = [];
            let essenQualEval = {};
            essenQualEval.totalEvals = 1;
            essenQualEval.essentialLearningEval = el.essentialLearningEval;
            essenLearnMap[el.name][el.essentialLearning].push(essenQualEval);
          } else {
            let alreadyAddedFlag = false;
            essenLearnMap[el.name][el.essentialLearning].map(function (qualEval) {
              if (qualEval.essentialLearningEval == el.essentialLearningEval) {
                alreadyAddedFlag = true;
                qualEval.totalEvals += 1;
              }
            });
            if (!alreadyAddedFlag) {
              let essenQualEval = {};
              essenQualEval.totalEvals = 1;
              essenQualEval.essentialLearningEval = el.essentialLearningEval;
              essenLearnMap[el.name][el.essentialLearning].push(essenQualEval);
            }
          }
        }
      } else {
        if (essenLearnMap[el.name] == null) {
          essenLearnMap[el.name] = {};
          essenLearnMap[el.name][el.essentialLearning] = {};
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
          essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
        } else {
          if (essenLearnMap[el.name][el.essentialLearning] == null) {
            essenLearnMap[el.name][el.essentialLearning] = {};
            essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
            essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
          } else {
            essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += el.essentialLearningEval;
            essenLearnMap[el.name][el.essentialLearning].totalEvals += 1;
          }
        }
      }
    }

    for (let w = 0; w < data.length; w++) {
      let el = data[w];
      for (const studName in essenLearnMap) {
        if (essenLearnMap.hasOwnProperty(studName)) {
          let essLearn = essenLearnMap[studName];
          if (el.name == studName && essLearn[el.essentialLearning] != null) {
            if (
              el.qualitativeLevels != null &&
              Object.keys(el.qualitativeLevels).length > 0 &&
              el.qualitativeEvalFlag
            ) {
              el.essentialLearningEval = null;
              for (const essLearnQual in essLearn) {
                if (essLearn.hasOwnProperty(essLearnQual) && el.essentialLearning == essLearnQual) {
                  let essLearnQualEvals = essLearn[essLearnQual];
                  for (let m = 0; m < essLearnQualEvals.length; m++) {
                    let qualEval = essLearnQualEvals[m];

                    if (el.essentialLearningEval == null) {
                      el.essentialLearningEval = qualEval.essentialLearningEval + " (" + qualEval.totalEvals + ")";
                    } else {
                      el.essentialLearningEval +=
                        ", " + qualEval.essentialLearningEval + " (" + qualEval.totalEvals + ")";
                    }
                  }
                }
              }
            } else {
              el.essenLearnAvg =
                Math.round(
                  (essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals) * 100
                ) / 100;
            }
          }
        }
      }
    }

    return data;
  },
  kstkSchoolContactSplitByEducationLevelAndArchitecture: function kstkSchoolContactSplitByEducationLevelAndArchitecture(
    scope,
    data
  ) {
    let educationLevels = scope.getFromTableData("Class_Plan_Education_Levels");
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.educationLevel != null && Array.isArray(el.educationLevel) && el.educationLevel.length > 0) {
        let alreadyCounted2ndAnd3rdEducationLevel = false;
        for (let w = 0; w < el.educationLevel.length; w++) {
          let newEl = JSON.parse(JSON.stringify(el));
          let educationLevel = el.educationLevel[w];
          let fromTableEducationLevel = educationLevels.filter((el) => el.id == educationLevel)[0];
          if (fromTableEducationLevel != null) {
            let parsedEducationLevel = educationLevels.filter((el) => el.id == educationLevel)[0].educationLevel;
            if (parsedEducationLevel != null && parsedEducationLevel != "") {
              if (parsedEducationLevel.indexOf("2") != -1 || parsedEducationLevel.indexOf("3") != -1) {
                if (alreadyCounted2ndAnd3rdEducationLevel) {
                  continue;
                }
                alreadyCounted2ndAnd3rdEducationLevel = true;
                parsedEducationLevel = "2º e 3º Ciclos";
              } else if (parsedEducationLevel.indexOf("PRE") != -1) {
                parsedEducationLevel = " PRE";
              }
              newEl.parsedEducationLevel = parsedEducationLevel;

              if (
                newEl.architectureTypes != null &&
                Array.isArray(newEl.architectureTypes) &&
                newEl.architectureTypes.length > 0
              ) {
                for (let w = 0; w < newEl.architectureTypes.length; w++) {
                  let schoolArchInfo = newEl.architectureTypes[w];

                  if (
                    schoolArchInfo.educationLevel != null &&
                    Array.isArray(schoolArchInfo.educationLevel) &&
                    schoolArchInfo.educationLevel.length > 0 &&
                    schoolArchInfo.arquitectureType != null
                  ) {
                    let newSubElement = JSON.parse(JSON.stringify(newEl));
                    newSubElement.arquitectureType = schoolArchInfo.arquitectureType;
                    newSubElement.clusterSchoolArchType =
                      scope.parseValue(newSubElement.cluster, "cluster", "Class_Plan_Clusters") +
                      scope.parseValue(newSubElement.school, "school", "Class_Plan_Schools") +
                      newSubElement.arquitectureType;
                    newSubElement.schoolEducationLevel =
                      scope.parseValue(newSubElement.school, "school", "Class_Plan_Schools") +
                      (newSubElement.parsedEducationLevel == "PRE"
                        ? " " + newSubElement.parsedEducationLevel
                        : newSubElement.parsedEducationLevel);
                    newSubElement.splitFlag = true;
                    cleanNewElement(newSubElement);
                    data.push(newSubElement);
                  }
                }
              }
            }
          }
        }
      }

      data.splice(i, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
};

function getCurrentScholarYear() {
  let date = new Date();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  if (monthIndex + 1 < 9) {
    return (year - 1).toString();
  } else {
    return year.toString();
  }
}

function getMonthWithNumberBefore(month) {
  if (month != null) {
    let parsedMonth = month.toLowerCase();
    switch (parsedMonth) {
      case "janeiro":
        return "01 janeiro";
      case "fevereiro":
        return "02 fevereiro";
      case "março":
        return "03 março";
      case "abril":
        return "04 abril";
      case "maio":
        return "05 maio";
      case "junho":
        return "06 junho";
      case "julho":
        return "07 julho";
      case "agosto":
        return "08 agosto";
      case "setembro":
        return "09 setembro";
      case "outubro":
        return "10 outubro";
      case "novembro":
        return "11 novembro";
      case "dezembro":
        return "12 dezembro";
      default:
        break;
    }
  } else {
    return null;
  }
}

function getMonthFromDateMs(date) {
  if (!isNaN(Number(date)) && date != null) {
    const formatter = new Intl.DateTimeFormat("pt", {
      month: "long",
    });
    const month = formatter.format(new Date(date));
    return month;
  } else {
    return null;
  }
}

function classPlanGetQuantitativeEvalsFromQualitative(qualitativeTotalEvals, qualitativeEvalLevels) {
  let totalEvals = 0;
  let numEvals = 0;
  qualitativeTotalEvals.forEach((qualitativeLevel) => {
    qualitativeEvalLevels.forEach((qualitativelLevelDesc) => {
      if (
        qualitativeLevel == qualitativelLevelDesc.qualitativeLevel &&
        qualitativelLevelDesc.quantitativeLevel != null &&
        !isNaN(Number(qualitativelLevelDesc.quantitativeLevel))
      ) {
        totalEvals += qualitativelLevelDesc.quantitativeLevel;
        numEvals += 1;
      }
    });
  });
  return Math.round((totalEvals / numEvals) * 100) / 100;
}

function ptConvertEvalToFinalEvalLevel(selected, evalField) {
  if (selected[evalField] != null && !isNaN(selected[evalField]) && selected.finalEvalLevels != null) {
    let finalLevel;
    for (let i = 0; i < selected.finalEvalLevels.length; i++) {
      let finalEvalLevel = selected.finalEvalLevels[i];
      let roundedEssenLearnAvg = Math.round(selected[evalField]);
      if (
        roundedEssenLearnAvg >= finalEvalLevel.bottomEvalLimit &&
        roundedEssenLearnAvg <= finalEvalLevel.topEvalLimit
      ) {
        if (!isNaN(finalEvalLevel.evalLevel)) {
          finalLevel = Number(finalEvalLevel.evalLevel);
        } else {
          finalLevel = finalEvalLevel.evalLevel;
        }
      }
    }
    if (finalLevel == null) {
      return null;
    } else {
      return finalLevel;
    }
  } else {
    return null;
  }
}
